export const equals = [
  {
    label: 'É igual a',
    value: 'equal'
  },
  {
    label: 'Não é igual a',
    value: 'not-equal'
  }
]
