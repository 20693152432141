<template>
  <div class="flex items-center">
    <div>
      <erp-select :options="options" v-model="criterio.comparativo" />
    </div>
    <div class="m-l">
      <conservacao-select class="u-field-no-label" label-width="110px" field-view="lr"
                          v-model="criterio.value" />
    </div>
  </div>
</template>

<script>
import {equals} from './matches/equals'
import {ErpSelect} from 'uloc-vue-plugin-erp'
import criteriaMixin from './criteriaMixin'
import ConservacaoSelect from '../../../bem/components/include/ConservacaoSelect'

export default {
  name: 'Conservacao',
  mixins: [criteriaMixin],
  props: {
    criterio: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      options: [
        ...equals
      ]
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
  },
  components: {ConservacaoSelect, ErpSelect}
}
</script>
